import request from '@/utils/request'


// 查询订单-商铺保证金订单列表
export function listShopMargin(query) {
  return request({
    url: '/order/shop-margin/list',
    method: 'get',
    params: query
  })
}

// 查询订单-商铺保证金订单分页
export function pageShopMargin(query) {
  return request({
    url: '/order/shop-margin/page',
    method: 'get',
    params: query
  })
}

// 查询订单-商铺保证金订单详细
export function getShopMargin(data) {
  return request({
    url: '/order/shop-margin/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-商铺保证金订单
export function addShopMargin(data) {
  return request({
    url: '/order/shop-margin/add',
    method: 'post',
    data: data
  })
}

export function payShopMargin(data) {
  return request({
    url: '/order/shop-margin/pay',
    method: 'post',
    data: data
  })
}

// 修改订单-商铺保证金订单
export function updateShopMargin(data) {
  return request({
    url: '/order/shop-margin/edit',
    method: 'post',
    data: data
  })
}

// 删除订单-商铺保证金订单
export function delShopMargin(data) {
  return request({
    url: '/order/shop-margin/delete',
    method: 'post',
    data: data
  })
}
// 删除订单-商铺保证金订单
export function refundShopMargin(data) {
  return request({
    url: '/order/shop-margin/refund',
    method: 'post',
    data: data
  })
}